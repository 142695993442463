// import {
//     TimelineMax,
//     TweenMax
// } from 'gsap';
// import "isotope-layout/dist/isotope.pkgd"
import AverageImageColor from "./averageImageColor";


class GridAnimation {

    constructor(container) {

        let that = this;

        // Wait for everything to load
        $(window).on('load', function () {

            // Calculate and set the Project background colors
            that.setProjectColors(container);

            that.animateGrid(container).play();
            
        });

    }


    setProjectColors(container) {

        let imgs = container.find('img');

        // Set card background colors
        $.each(imgs, (key, element) => {
            let averageColor = new AverageImageColor(element);
            let curtain = $(element).parents('.card').find('.card__curtain');
            let bgColor = 'rgb(' + averageColor.rgb.r + ',' + averageColor.rgb.g + ',' + averageColor.rgb.b + ')';
            curtain.css("backgroundColor", bgColor);
        })
    }


    // Create animation timeline
    animateGrid(container) {

        let
            items = container.find('.grid__item'),
            projects = container.find('.card'),
            labels = container.find('.card__lbl'),
            images = container.find('.card__img'),
            curtains = container.find('.card__curtain'),
            tl = new TimelineMax();

        tl
            .set(items, {
                autoAlpha: 1
            })
            .staggerFrom(items, 0.6, {
                y: "+=15%",
                clearProps: "transform"
            }, 0.1, "a")
            .staggerFrom(curtains, 0.3, {
                y: "+=100%",
                clearProps: "transform"
            }, 0.1, "a")
            .staggerFrom(images, 0.6, {
                autoAlpha: 0,
                scale: "1.1",
                clearProps: "transform"
            }, 0.1, "a+=0.3")
            .staggerFrom(labels, 0.6, {
                autoAlpha: 0,
                x: "-=10",
                clearProps: "transform"
            }, 0.1, "a+=0.6")
            .to(projects, 0.01, {
                className: "+=ready",
                clearProps: "transform"
            }, "a+=0.9")
            .stop();

        return tl;

    }

}

export default GridAnimation;
