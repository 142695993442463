// import 'gsap';
import drawSVG from './../vendor/gsap/DrawSVGPlugin';
import Breakpoint from './breakpoints';

class MobileNav {


    constructor() {

        // Variables 
        this.navBtn = $('.nav__burger');
        this.navMenu = $('.nav');

        // Timeline variables
        this.navBtnTl = new TimelineMax();
        this.navMenuTl = new TimelineMax();
        TweenLite.defaultEase = Power2.easeOut;

        // Breakpoints
        this.breakpoints = new Breakpoint(["xs", "sm", "md"]);
        this.breakpointChange();

        // Initialize timeline animations
        if (this.breakpoints.inside()) {
            this.init();
            this.events();
        }

    }


    init() {

        let that = this;

        that.navBtn.css("visibility", "visible");
        that.navMenuTimeline();
        that.navBtnTimeline();

    }


    events() {

        let that = this;

        // Burger Button Click
        that.navBtn.click(function () {
            that.toggleNavigation();
        });

        // Outside click
        $('.nav__bg').click(function () {
            that.toggleNavigation();
        })
    }


    breakpointChange() {

        let that = this;

        // Receive breakpoint changes from Breakpoints module
        that.breakpoints.alert.on("breakpointRangeChange", function () {

            if (that.breakpoints.inside()) {
                console.log("inside");
                that.init();
                that.events();
            } else {
                console.log("outside");
                that.resetTimeline(that.navBtnTl);
                that.resetTimeline(that.navMenuTl);
            }

        })

    }


    toggleNavigation() {

        let that = this;

        if (that.navMenu.hasClass("active")) {
            that.navBtnTl.reverse();
            that.navMenuTl.reverse();
            that.navMenuTl.eventCallback("onReverseComplete", myFunction);
            function myFunction() {
                that.navMenu.hide();
            }

        } else {
            that.navMenu.show();
            that.navBtnTl.play();
            that.navMenuTl.play();
        }

    }


    navMenuTimeline() {

        let that = this;

        // build menu animation
        let
            mobileBg = that.navMenu.find('.nav__bg'),
            mobileLinks = that.navMenu.find('.nav__link');

        that.navMenuTl
            .to(that.navMenu, 0.001, {
                css: {
                    display: "block"
                }
            })
            .from(mobileBg, 0.6, {
                autoAlpha: 0,
                x: "+=100%"
            }, "a")
            .staggerFrom(mobileLinks, 0.3, {
                x: "+=30",
                autoAlpha: 0
            }, 0.05, "a+=0.3")
            .stop();

    }


    navBtnTimeline() {

        let that = this;

        // build button animation
        let
            navBtnSvg = that.navBtn.find('#nav__burger-svg'),
            burger = navBtnSvg.find('#burger line'),
            closeUp = navBtnSvg.find('#close line#up'),
            closeDown = navBtnSvg.find('#close line#down');

        that.navBtnTl
            .staggerFromTo(burger, 0.2, {
                x: "0",
                drawSVG: "0% 100%"
            }, {
                x: "+=10",
                drawSVG: "0% 0%"
            }, 0.1)
            .fromTo(closeDown, 0.2, {
                x: "-=10",
                y: "-=10",
                drawSVG: "100% 100%"
            }, {
                x: "0",
                y: "0",
                drawSVG: "0% 100%"
            }, "-=0.05")
            .fromTo(closeUp, 0.2, {
                x: "-=10",
                y: "+=10",
                drawSVG: "100% 100%"
            }, {
                x: "0",
                y: "0",
                drawSVG: "0% 100%"
            }, "-=0.05")
            .to(that.navMenu, 0.001, {
                className: "+=active"
            })
            .stop();

    }


    // Undo everything that timeline has done to the DOM
    resetTimeline(tl) {

        // Clear all css props from previous timelines
        function clearTimelineProps(tl) {
            // console.log("clear props");
            let tweens = tl.getChildren();
            $.each(tweens, function (i, tween) {
                if (tween.target != null || tween.target != undefined) {
                    TweenLite.set(tween.target, {
                        clearProps: "all"
                    });
                }
            });
        }

        // Revert all add css classes from the DOM
        tl.seek(0).stop();

        // Clear all css props from previous timelines
        clearTimelineProps(tl);

        // Kill the reference
        tl = null;

    }

}

export default MobileNav;
