// Custom Modules
import MobileNav from './modules/mobileNav';
// import Isotope from './modules/isotope';
import GridAnimation from './modules/gridAnimation';
import Slider from './modules/slider';
import BlogSlider from './modules/blogSlider';
import Video from './modules/video';
// import HeroVideo from './modules/heroVideo';
import NavigationHover from './modules/navigationHover';
import ECard from './modules/eCard';


// Initialize mobile Navigation
new MobileNav();

// Initialize ISOTOPE layout
// if ($('.grid').length > 0) {
//     new Isotope($('.grid'));
// }

// Initialize Grid animation
if ($('.grid').length > 0) {
    new GridAnimation($('.grid'));
}

// Initialize ISOTOPE layout
if ($('.slider').length > 0) {
    new Slider($('.slider'));
}

// Initialize ISOTOPE layout
if ($('.slider--blog').length > 0) {
    new BlogSlider($('.slider--blog'));
}

// Custom hover effect
if ($('.nav__link').length > 0) {
    new NavigationHover($('.nav__link'));
}

// After page load
$(window).on('load', function () {

    // Privacy settings allow youtube videos
    if ($('body').hasClass("privacy--allowed")) {

        // Load youtube iframe api if there are any youtube videos on the page
        if ($('.video').length > 0 || $('.hero--video').length > 0) {
            loadYouTubeIframeAPI();
        }

    }

});


function loadYouTubeIframeAPI() {

    // This code loads the IFrame Player API code asynchronously.
    if (!$('body').hasClass("video")) {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

}

// When Youtube Iframe API has successfully loaded
window.onYouTubeIframeAPIReady = function () {

    console.log("🎉 onYouTubeIframeAPIReady 🎉");

    // Init YouTube video
    if (!$('body').hasClass("video")) {
        if ($('.video').length > 0) {
            new Video($('.video'));
        }
    }

    // Init YouTube hero video (background video)
    if ($('.video--hero').length > 0) {
        new Video($('.video--hero'));
    }
}


// Autoplay e-Card

if ($('body.e-card video').length > 0) {    
    new ECard();
}
