class NavigationHover {

    constructor(links) {

        this.init(links);

    }

    init(links) {

        // Set timelines and hover events for all links
        links.each((key, val) => {
            let link = $(val);
            link.hoverTimeline = hoverTimeline(link);
            if (!link.hasClass("active")) {
                link.bind({
                    mouseenter: () => {
                        link.hoverTimeline.tweenFromTo("start", "middle");
                    },
                    mouseleave: () => {
                        link.hoverTimeline.tweenFromTo("middle", "end");
                    }
                })
            }
        });

        // Create hover-timeline
        function hoverTimeline(link) {

            let
                time = 0.3,
                hover = link.find('.hover'),
                tl = new TimelineMax();

            tl
                .add("start") // Start here
                .to(link, time, {
                    color: "#ffffff"
                }, "a")
                .from(hover, time, {
                    scaleY: 0.001,
                    y: "+=20",
                    autoAlpha: 0,
                }, "a")
                .add("middle") // Middle/active state
                .to(link, time, {
                    color: "#414042"
                }, "b")
                .to(hover, time, {
                    scaleY: 0.001,
                    y: "-=20",
                    autoAlpha: 0,
                    clearProps: "all",
                }, "b")
                .add("end") // End here
                .stop();

            return tl;
        }

    }

}

export default NavigationHover;
