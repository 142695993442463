import 'gsap';
// import Youtube from './youtube';

class Video {

    constructor(container) {

        let that = this;

        // Save all videos in an array
        this.videos = [];

        this.heroVideos = [];

        // Create all video objects
        container.each(function (key, video) {
            if ($(video).hasClass("video--hero")) {
                that.heroVideos.push(that.initHeroVideo(video));
            } else {
                that.videos.push(that.initVideo(video));
            }
        });

        // Make variable global
        window.videos = this.videos;

    }

    initHeroVideo(video) {

        video = $(video);
        video.frame = video.find('.youtube__container');
        video.id = video.frame.data('video-id');

        // Create iFrame and YouTube player
        video.player;
        video.playerID = "youtube-player--" + video.id;

        video.player = new YT.Player(video.playerID, {
            width: '800',
            height: '450',
            videoId: video.id,
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange,
            },
            playerVars: {
                rel: 0,
                showinfo: 0,
                modestbranding: 1,
                mute: 1,
                rel: 0,
                loop: 1,
                controls: 0
            },
        });

        // Callback: The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            video.player.playVideo();
            console.log("Player ready");
        }

        function onPlayerStateChange(event) {
            // console.log("State changed", event.data, YT.PlayerState);
            if (event.data == 0) {
                video.player.playVideo();
            }
        }
    }




    initVideo(video) {

        let that = this;

        video = $(video);
        video.frame = video.find('.youtube__container');
        video.id = video.frame.data('video-id');
        video.poster = video.parent().find('.video__poster');
        video.posterIcon = video.parent().find('.video__btn');
        video.timeline = animateVideo(video);

        // Bind click event on video
        video.click(function () {
            if (!video.hasClass("active")) {
                video.timeline.play();
                video.player.playVideo();
            }
            if (video.parents('.slider__slide--video')) {
                video.parents('.slider__slide--video').addClass("activated");
            }
        });

        // Create iFrame and YouTube player
        video.player;
        video.playerID = "youtube-player--" + video.id;

        video.player = new YT.Player(video.playerID, {
            width: '800',
            height: '450',
            videoId: video.id,
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange,
            },
            playerVars: {
                rel: 0,
                showinfo: 0,
                modestbranding: 1
            },
            autoplay: 1,
            rel: 0,
        });

        // Callback: The API will call this function when the video player is ready.
        function onPlayerReady(event) {

            // Pause video if the current slide is leaving
            video.onChange = video.parents('.slider__slide--video').on('oldClassChange', function (e) {
                if (video.parents('.activated').length) {
                    video.pause();
                }
            })

            // Resume Video if the next active slide contains a paused video
            video.onChange = video.parents('.slider__slide--video').on('newClassChange', function (e) {
                if (video.parents('.activated').length) {
                    video.play();
                }
            })
        }

        // 5. The API calls this function when the player's state changes.
        //    The function indicates that when playing a video (state=1),
        //    the player should play for six seconds and then stop.
        // let done = false;

        function onPlayerStateChange(event) {
            // console.log("State changed", event.data, YT.PlayerState);
            // if (event.data == YT.PlayerState.PLAYING && !done) {
            //     setTimeout(stopVideo, 6000);
            //     done = true;
            // }
        }

        // Play video
        video.play = function () {
            video.player.playVideo();
        }

        // Stop video
        video.stop = function () {
            video.player.stopVideo();
        }

        // Pause video
        video.pause = function () {
            video.player.pauseVideo();
        }




        // Animate transition
        function animateVideo(video) {

            TweenLite.defaultEase = Power2.easeOut;
            let tl = new TimelineMax({
                delay: 0,
            });

            tl
                .to(video, 0.001, {
                    className: "+=active"
                })
                .to(video.posterIcon, 0.6, {
                    autoAlpha: 0,
                    scale: "0.1"
                }, "a")
                .to(video.poster, 0.6, {
                    autoAlpha: 0
                }, "a+=0.3")
                .from(video.frame, 0.6, {
                    autoAlpha: 0
                }, "b")
                .stop();

            return tl;

        }

        return video;

    }

}

export default Video;
