import Swiper from 'swiper';

class Slider {


    constructor(container) {

        this.currentTransitionSpeed = 0;

        // Init all sliders
        this.init(container);

    }


    init(slider) {

        const that = this;

        // Build multiple sliders
        slider.each(function (index, value) {

            let slider = $(value);

            // console.log("init slider", slider);
            let mainContainer = slider.find('.slider__main > .swiper-container');
            let thumbsContainer = slider.find('.slider__thumbs > .swiper-container');

            // Thumbnail gallery
            let sliderThumbs = new Swiper(thumbsContainer, {
                spaceBetween: 0,
                slidesPerView: 4,
                grabCursor: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                direction: "horizontal",
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    320: {
                        slidesPerView: 2,
                    }
                }
            });

            // Swiper gallery
            let sliderMain = new Swiper(mainContainer, {
                speed: 1000,
                // autoplay: {
                // 	delay: 6000,
                // 	disableOnInteraction: true,
                // },
                spaceBetween: 0,
                slidesPerView: '1',
                autoHeight: true,
                grabCursor: true,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // --- custom transiton start --- //
                watchSlidesProgress: true,
                virtualTranslate: true,
                watchSlidesVisibility: true,
                effect: "myCustomTransition",
                // --- custom transiton end --- //
                thumbs: {
                    swiper: sliderThumbs
                },
                on: {
                    init: function () {
                        const that = this;
                        TweenMax.to(slider.find('.slider__thumbs'), 0.6, {
                            autoAlpha: "1"
                        });
                    },
                    progress(progress) {
                        const swiper = this;
                        if (swiper.params.effect !== "myCustomTransition") return;
                        that.progress(swiper, progress);
                    },
                    setTransition(transition) {
                        const swiper = this;
                        if (swiper.params.effect !== "myCustomTransition") return;
                        that.setTransition(swiper, transition);
                    },
                    setTranslate(translate) {
                        const swiper = this;
                        if (swiper.params.effect !== "myCustomTransition") return;
                        that.setTranslate(swiper, translate);
                    },
                    slideChange() {
                        const swiper = this;
                        $(swiper.slides[swiper.previousIndex]).trigger('oldClassChange');
                        $(swiper.slides[swiper.activeIndex]).trigger('newClassChange');
                    }
                },
            });
        });

    }

    getTransitionSpeed() {
        const transitionSpeed = this.currentTransitionSpeed;
        // don't forget to reset the variable for future calls
        this.currentTransitionSpeed = 0;
        return transitionSpeed;
    }

    getActiveIndexBeforeTransitionStart(swiper, slides) {
        const isDragging = !Math.abs(slides[swiper.activeIndex].progress === 1);
        if (isDragging) {
            return swiper.slidesGrid.indexOf(
                -swiper.touchEventsData.startTranslate || swiper.params.initialSlide
            );
        } else {
            return swiper.activeIndex;
        }
    }

    getDirection(animationProgress) {
        if (animationProgress === 0) {
            return "NONE";
        } else if (animationProgress > 0) {
            return "NEXT";
        } else {
            return "BACK";
        }
    }

    progress(swiper, progress) {
        /* 
        if you need to change something for each progress
        do it here (progress variable is always in range from 0 to 1) representing progress of the whole slider 
        */
        // console.log(progress);
    }

    setTransition(swiper, transitionSpeed) {
        this.currentTransitionSpeed = transitionSpeed;
        // console.log("transition", transitionSpeed);
    }


    setTranslate(swiper, wrapperTranslate) {
        const durationInSeconds = this.getTransitionSpeed() / 1000;
        // convert slides object to plain array
        const slides = Object.values(swiper.slides).slice(0, -1);

        // get the index of the slide active before transition start (activeIndex changes halfway when dragging)
        const originIndex = this.getActiveIndexBeforeTransitionStart(
            swiper,
            slides
        );
        // get information about animation progress from the active slide - the active slide's value is always -1 to 1.
        /* 
        every slide has a progress attribute equal to the "distance" from the current active index.
        */
        const animationProgress = slides[originIndex].progress;
        // you can then get the drag direction like so:
        const direction = this.getDirection(animationProgress);
        // console.log(direction);

        // do magic with each slide
        slides.map((slide, index) => {
            // to put the slides behind each other we have to set their CSS translate accordingly since by default they are arranged in line.
            const offset = slide.swiperSlideOffset;
            let x = -offset;
            if (!swiper.params.virtualTranslate) x -= swiper.translate;
            let y = 0;
            if (!swiper.isHorizontal()) {
                y = x;
                x = 0;
            }
            TweenMax.set(slide, {
                x,
                y
            });

            // do our animation stuff!
            const clip = (val, min, max) => Math.max(min, Math.min(val, max));
            const ZOOM_FACTOR = 0.05;

            const opacity = Math.max(1 - Math.abs(slide.progress), 0);

            const clippedProgress = clip(slide.progress, -1, 1);
            const scale = 1 - ZOOM_FACTOR * clippedProgress;

            // you can do your CSS animation instead of using tweening.
            TweenMax.to(slide, durationInSeconds, {
                scale,
                opacity
            });
        });
    }

}

export default Slider;
