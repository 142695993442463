class ECard {

    constructor() {

        console.log("ecard video");

        let videoElem = document.querySelector('body.e-card video');
        let startPlayPromise = videoElem.play();

        let btn = document.querySelector('.playBtn');
        btn.addEventListener('click', (e) => {
            videoElem.play();
            TweenLite.to(btn, 0.4, {
                autoAlpha: 0,
                y: "+=15",
                scale: 0.95
            });
            videoElem.style.opacity = 1;

        })

        if (startPlayPromise !== undefined) {
            startPlayPromise.then(() => {
                // Start whatever you need to do only after playback
                // has begun.
                console.log("video is playing");
            }).catch(error => {
                if (error.name === "NotAllowedError") {
                    showPlayButton(videoElem);
                } else {
                    // Handle a load or playback error
                }
            });
        }

        function showPlayButton(v) {
            console.log("show play button");
            btn.style.display = "flex";
            videoElem.style.opacity = 0.2;
            TweenLite.from(btn, 0.4, {
                autoAlpha: 0,
                y: "+=15",
                scale: 0.95
            });

        }

    }


}

export default ECard;
